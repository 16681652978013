import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import TecalisLogo from '../../new/resources/tecalis.svg';
import { capitalizeString } from '../../utils/string-utils';
import CardPost from '../v2023/UI/Card/card-post';
import LinkButton from '../v2023/UI/LinkButton/link-button';
import { NavCard } from './card';
import { LanguageDropdown } from './language';
import { Newsletter } from './newsletter';
import { redirectToThankYou } from '../../utils/thank-you-access';

export const Menu = ({ lang, translates }) => {
	useEffect(() => {
		// Manage to open the submenu
		const openSubmenu = (thisItem) => {
			thisItem.classList.add('show');
			activeSubmenu = thisItem
		};

		// Manage to close the submenu
		const closeSubmenu = (thisItem) => {
			thisItem.classList.remove('show');
			activeSubmenu = null
		};
		
		// Manage to close all the submenus
		const closeAllSubmenu = () => allMenuItems.forEach(submenu => closeSubmenu(submenu))

		const handleMouseEnter = (e) => {
			const submenu = e.target
			// Removing the timer, so we cancel every previous action pending to don't open or close other submenus
			clearTimeout(timer)

			// If there is not an active submenu, we instantly open the hovered submenu
			if (!activeSubmenu) {
				openSubmenu(submenu)
			} else {
				timer = setTimeout(() => {
					// If past the timeout we close the other menu that's open
					if (activeSubmenu) {
						closeSubmenu(activeSubmenu)
					}
					// And open the current menu
					openSubmenu(submenu)
				}, delay);
			}
		}

		const handleMouseLeave = (e) => {
			// Removing the timer, so we cancel every previous action pending to don't open or close other submenus
			clearTimeout(timer)
			timer = setTimeout(() => {
				// Closing the exited submenu
				closeSubmenu(e.target)
			}, delay);
		}

		// The timer will hold the timeout id, so we can cancel the actions
		// The activeSubmenu is to know what submenu should be open
		let timer, activeSubmenu = null
		// The delay that the menu will hold to open and close the item
		const delay = 200;
		const allMenuItems = document.querySelectorAll('#header .options__option');
		// For each menu item
		for (let i = 0; i < allMenuItems.length; i++) {
			const thisItem = allMenuItems[i];
			// We create a mouse enter event, it will remove the class from the others option and add the active class
			thisItem.addEventListener('mouseenter', (e) => handleMouseEnter(e));

			thisItem.addEventListener('mouseleave', (e) => handleMouseLeave(e));
		}

		// When leaving the header, closing all the submenus
		const header = document.getElementById('header')
		header.addEventListener('mouseleave', () => closeAllSubmenu())

		// Removing all the event listener on the page dismount
		return () => {
			for (let i = 0; i < allMenuItems.length; i++) {
				const thisItem = allMenuItems[i];
				// Removing the event listener to avoid cache
				thisItem.removeEventListener('mouseenter', (e) => handleMouseEnter(e));
				thisItem.removeEventListener('mouseleave', (e) => handleMouseLeave(e));
			}
			header.removeEventListener('mouseleave', () => closeAllSubmenu())
		};
	});

	const { menuData } = useStaticQuery(graphql`
		query {
			menuData: allStrapi2024Menu {
				edges {
					node {
						locale
						product_menu_title
						product_main_title
						product_main_items {
							title
							description
							link
							icon {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 35
											quality: 100
											placeholder: BLURRED
											formats: [WEBP]
											breakpoints: [32]
										)
									}
								}
							}
						}
						product_others_title
						product_others_items {
							text
							url
						}
						product_last_title
						product_last_items {
							text
							url
							tag
							image {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 134, quality: 100, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
						}
						product_last_see_all {
							text
							url
							blank
						}
						solutions_menu_title
						solutions_industry_title
						solutions_industry_items {
							title
							description
							image {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 32
											quality: 100
											placeholder: BLURRED
											formats: [WEBP]
											breakpoints: [27]
										)
									}
								}
							}
							sector {
								url
								title
								description
							}
						}
						solutions_industry_see_all {
							text
							url
						}
						solutions_use_cases_title
						solutions_use_cases_items {
							text
							url
						}
						solutions_use_cases_see_all {
							text
							url
						}
						solutions_study_cases_title
						solutions_study_cases_items {
							title
							case_study {
								url
							}
							image {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(height: 32, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
						}
						solutions_study_cases_see_all {
							text
							url
						}
						about_tecalis_menu_title
						about_tecalis_know_us_title
						about_tecalis_know_us_items {
							title
							description
							link
							icon {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 32, quality: 100, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
						}
						about_tecalis_news_title
						about_tecalis_news_items {
							title
							actualidad {
								seo_url
							}
							image {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
							tag
						}
						about_tecalis_news_see_all {
							text
							url
						}
						resources_menu_title
						resources_blog_item {
							description
							url
							icon {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 458, quality: 100, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
						}
						resources_kc_item {
							description
							url
							icon {
								name
								alternativeText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 458, quality: 100, placeholder: BLURRED, formats: [WEBP])
									}
								}
							}
						}
						resources_newsletter_title
						resources_newsletter_description
						resources_newsletter_form {
							region
							portal_id
							form_id
						}
						thank_you_content_suscription {
							url
						}
						partners_menu_link {
							text
							url
						}
						session_login_button {
							text
							url
							blank
						}
						session_register_button {
							id
							text
							url
							blank
						}
					}
				}
			}
		}
	`);
	const industriesUrl = lang === 'en' ? '/industries' : '/es/industrias';
	const caseStudiesUrl = lang === 'en' ? '/case-studies' : '/es/casos-exito';
	const newsUrl = lang === 'en' ? '/news' : '/es/actualidad';
	const menu = menuData.edges.find((item) => item.node.locale === lang);
	menu.node.solutions_industry_items.forEach((item) => {
		item.link = `${industriesUrl}/${item.sector.url}`;
	});
	menu.node.solutions_study_cases_items.forEach((item) => {
		item.url = `${caseStudiesUrl}/${item.case_study.url}`;
	});
	menu.node.about_tecalis_news_items.forEach((item) => (item.url = `${newsUrl}/${item.actualidad.seo_url}`));
	const homeUrl = lang === 'en' ? '/' : `/${lang}`;
	const [openMenu, setOpenMenu] = useState(false);
	const [openMenuItem, setOpenMenuItem] = useState(-1);

	const newsletter = {
		resources_newsletter_title: menu.node.resources_newsletter_title,
		resources_newsletter_description: menu.node.resources_newsletter_description,
		resources_newsletter_form: menu.node.resources_newsletter_form,
		thank_you_content_suscription: menu.node.thank_you_content_suscription
	};
	const onClickButtonMenu = (open = true) => {
		if (typeof document !== 'undefined') {
			document.body.style.overflowY = open ? 'hidden' : 'auto';
		}

		setOpenMenu(open);
	};
	const selectOpenMenuItem = (index) => {
		if (openMenuItem === index) {
			setOpenMenuItem(-1);
		} else {
			setOpenMenuItem(index);
		}
	};

	return (
		<header id="header" className="header">
			<div className="container">
				<div className="header__logo">
					<Link to={`${homeUrl}`}>
						<img src={TecalisLogo} alt="Tecalis" title="Tecalis" />
					</Link>
				</div>
				<div className="header__menu-option">
					<ul className="header__menu-option__options hidden-md">
						<MenuSection
							title={menu.node.product_main_title}
							mainTitle={menu.node.product_menu_title}
							mainItems={menu.node.product_main_items}
							secondaryTitle={menu.node.product_others_title}
							secondaryItems={menu.node.product_others_items}
							tertiaryTitle={menu.node.product_last_title}
							tertiaryItems={menu.node.product_last_items}
							tertiarySeeMore={menu.node.product_last_see_all}
							tertiaryItemsSize="sm"
						/>
						<MenuSection
							title={menu.node.solutions_menu_title}
							mainTitle={menu.node.solutions_industry_title}
							mainItems={menu.node.solutions_industry_items}
							mainSeeMore={menu.node.solutions_industry_see_all}
							secondaryTitle={menu.node.solutions_use_cases_title}
							secondaryItems={menu.node.solutions_use_cases_items}
							secondarySeeMore={menu.node.solutions_use_cases_see_all}
							tertiaryTitle={menu.node.solutions_study_cases_title}
							tertiaryItems={menu.node.solutions_study_cases_items}
							tertiaryItemsSize="xs"
							tertiarySeeMore={menu.node.solutions_study_cases_see_all}
						/>
						<MenuSection
							title={menu.node.about_tecalis_menu_title}
							mainTitle={menu.node.about_tecalis_know_us_title}
							mainItems={menu.node.about_tecalis_know_us_items}
							tertiaryTitle={menu.node.about_tecalis_news_title}
							tertiaryItems={menu.node.about_tecalis_news_items}
							tertiarySeeMore={menu.node.about_tecalis_news_see_all}
						/>
						<MenuSection
							lang={lang}
							title={menu.node.resources_menu_title}
							blog={menu.node.resources_blog_item}
							kc={menu.node.resources_kc_item}
							newsletter={newsletter}
						/>
						<li className="options__option">
							<Link to={menu.node.partners_menu_link.url}>{menu.node.partners_menu_link.text}</Link>
						</li>
					</ul>
					<div className="header__menu-option__actions hidden-md">
						<LanguageDropdown lang={lang} translates={translates} shortLabel />
						<LinkButton button={menu.node.session_login_button} className="link" />
						<LinkButton button={menu.node.session_register_button} className="button button--sm" />
					</div>
					<div className="header__menu-option__actions header__menu-option__actions--mobile show-md">
						<button className="button-icon" onClick={() => onClickButtonMenu(!openMenu)}>
							<i className={openMenu ? 'icon-close' : 'icon-menu'} />
						</button>
					</div>
					{/* AQUI VA EL MENÚ MOVIL ANTIGUO CON EL NUEVO CONTENIDO */}
					{/* Mobile > Menú */}
					<div className={`header__menu-option__menu ${openMenu ? 'header__menu-option__menu--open' : ''}`}>
						<div className="menu__items">
							{/* Mobile > Productos */}
							<MenuSectionMobile
								menuItem="0"
								openMenuItem={openMenuItem}
								menuTitle={menu.node.product_main_title}
								mainTitle={menu.node.product_menu_title}
								mainItems={menu.node.product_main_items}
								secondaryTitle={menu.node.product_others_title}
								secondaryItems={menu.node.product_others_items}
								tertiaryTitle={menu.node.product_last_title}
								tertiaryItems={menu.node.product_last_items}
								tertiarySeeMore={menu.node.product_last_see_all}
								selectOpenMenuItem={selectOpenMenuItem}
							/>
							{/* Mobile > Industrias */}
							<MenuSectionMobile
								menuItem="1"
								openMenuItem={openMenuItem}
								menuTitle={menu.node.solutions_menu_title}
								mainTitle={menu.node.solutions_industry_title}
								mainItems={menu.node.solutions_industry_items}
								mainSeeMore={menu.node.solutions_industry_see_all}
								secondaryTitle={menu.node.solutions_use_cases_title}
								secondaryItems={menu.node.solutions_use_cases_items}
								secondarySeeMore={menu.node.solutions_use_cases_see_all}
								tertiaryTitle={menu.node.solutions_study_cases_title}
								tertiaryItems={menu.node.solutions_study_cases_items}
								tertiarySeeMore={menu.node.solutions_study_cases_see_all}
								tertiaryItemSize="xs"
								selectOpenMenuItem={selectOpenMenuItem}
							/>
							{/* Mobile > Sobre Tecalis */}
							<MenuSectionMobile
								menuItem="2"
								openMenuItem={openMenuItem}
								menuTitle={menu.node.about_tecalis_menu_title}
								mainTitle={menu.node.about_tecalis_know_us_title}
								mainItems={menu.node.about_tecalis_know_us_items}
								tertiaryTitle={menu.node.about_tecalis_news_title}
								tertiaryItems={menu.node.about_tecalis_news_items}
								tertiarySeeMore={menu.node.about_tecalis_news_see_all}
								selectOpenMenuItem={selectOpenMenuItem}
							/>
							{/* Mobile > Recursos */}
							<MenuSectionMobile
								menuItem="3"
								openMenuItem={openMenuItem}
								menuTitle={menu.node.resources_menu_title}
								blog={menu.node.resources_blog_item}
								kc={menu.node.resources_kc_item}
								newsletter={newsletter}
								lang={lang}
								selectOpenMenuItem={selectOpenMenuItem}
							/>
							{/* Mobile > Partners */}
							<div className="item">
								<div className="item__header">
									<h6>
										<Link to={menu.node.partners_menu_link.url}>
											{menu.node.partners_menu_link.text}
										</Link>
									</h6>
								</div>
							</div>
						</div>
						<div className="menu__actions">
							<LanguageDropdown lang={lang} translates={translates} shortLabel />
							<div className="menu__actions__buttons">
								<LinkButton
									button={menu.node.session_login_button}
									className="button button--secondary button--xl"
								/>
								<LinkButton
									button={menu.node.session_register_button}
									className="button button--primary button--xl"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

const MenuSection = ({
	mainClassName = '',
	title,
	mainTitle,
	mainItems,
	mainSeeMore,
	secondaryTitle,
	secondaryItems,
	secondarySeeMore,
	tertiaryTitle,
	tertiaryItems,
	tertiaryItemsSize = 'sm',
	tertiarySeeMore,
	blog,
	kc,
	lang,
	newsletter,
}) => {
	let tertiaryClass;
	if (tertiaryItemsSize) {
		tertiaryClass = `card--post--horizontal--${tertiaryItemsSize}`;
	}

	return (
		<li className={`options__option ${mainClassName}`}>
			<span>{title}</span>
			<div className="options__option__menu">
				{blog && kc && (
					<div className="menu__wrapper">
						<div className="container">
							<div className="menu__wrapper__section">
								<div className="grid-header">
									<CardPost image={blog.icon} name={blog.description} url={blog.url} />
									<CardPost image={kc.icon} name={kc.description} url={kc.url} />
								</div>
							</div>
							<div className="menu__wrapper__section menu__wrapper__section--gray menu__wrapper__section--newsletter">
								<Newsletter
									lang={lang}
									title={newsletter.resources_newsletter_title}
									description={newsletter.resources_newsletter_description}
									form={newsletter.resources_newsletter_form}
									hideTextsOnSubmit={false}
									redirectToThankYou={() => redirectToThankYou(lang, newsletter.thank_you_content_suscription.url)}
								/>
							</div>
						</div>
					</div>
				)}
				{!blog && !kc && (
					<div className="menu__wrapper">
						<div className="container">
							<div className="menu__wrapper__section">
								<div className="section__subsection">
									{mainTitle && <div className="section__title">{mainTitle}</div>}
									<div className="grid-header">
										{mainItems.map((mainItem, index) => {
											return (
												<NavCard
													key={index}
													link={mainItem.link}
													icon={mainItem.icon ?? mainItem.image}
													title={mainItem.title}
													description={mainItem.description}
													size="lg"
												/>
											);
										})}
									</div>
									{mainSeeMore && (
										<Link className="link--small" to={mainSeeMore.url}>
											{mainSeeMore.text}
										</Link>
									)}
								</div>
								{secondaryItems && (
									<div className="section__subsection">
										{secondaryTitle && <div className="section__title">{secondaryTitle}</div>}
										{secondaryItems.map((item, index) => {
											return <NavCard key={index} link={item.url} title={item.text} size="sm" />;
										})}
										{secondarySeeMore && (
											<Link className="link--small" to={secondarySeeMore.url}>
												{secondarySeeMore.text}
											</Link>
										)}
									</div>
								)}
							</div>
							{tertiaryItems && (
								<div className="menu__wrapper__section  menu__wrapper__section--gray">
									{tertiaryTitle && <div className="section__title">{tertiaryTitle}</div>}
									{tertiaryItems.map((item, index) => {
										const cardTitle = item.text ?? item.title;
										return (
											<CardPost
												key={index}
												name={cardTitle}
												image={item.image}
												horizontal
												tag={capitalizeString(item.tag)}
												tagClass={getTagColorClass(item.tag)}
												customClass={tertiaryClass}
												url={item.url}
											/>
										);
									})}
									{tertiarySeeMore && (
										<Link className="link--small" to={tertiarySeeMore.url}>
											{tertiarySeeMore.text}
										</Link>
									)}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</li>
	);
};

const MenuSectionMobile = ({
	openMenuItem,
	menuItem,
	menuTitle,
	mainTitle,
	mainItems,
	mainSeeMore,
	secondaryTitle,
	secondaryItems,
	secondarySeeMore,
	tertiaryTitle,
	tertiaryItems,
	tertiaryItemSize = 'sm',
	tertiarySeeMore,
	selectOpenMenuItem,
	blog,
	kc,
	newsletter,
	lang,
}) => {
	const handleMenu = () => {
		selectOpenMenuItem(menuItem);
	};

	let cardPostClass = `card--post--horizontal--${tertiaryItemSize}`;

	return (
		<div className={`item ${openMenuItem === menuItem ? 'item--active' : ''}`}>
			<div className="item__header" onClick={() => handleMenu()}>
				<h6 className="item__header__title">{menuTitle}</h6>
				<i className="icon-chevron-down"></i>
			</div>
			{!blog && !kc && (
				<div className="item__body">
					<div className="menu__section">
						<div className="menu__section__title">{mainTitle}</div>
						<div className="menu__section__items">
							<div className="grid-header">
								{mainItems.map((mainItem, index) => {
									return (
										<NavCard
											key={index}
											link={mainItem.link}
											icon={mainItem.icon ?? mainItem.image}
											title={mainItem.title}
											description={mainItem.description}
											size="lg"
										/>
									);
								})}
							</div>
						</div>
						{mainSeeMore && (
							<Link className="link--small" to={mainSeeMore.url}>
								{mainSeeMore.text}
							</Link>
						)}
					</div>

					{secondaryTitle && secondaryItems && (
						<div className="menu__section">
							<div className="menu__section__title">{secondaryTitle}</div>
							<div className="grid-header">
								{secondaryItems.map((item, index) => {
									return <NavCard key={index} link={item.url} title={item.text} size="sm" />;
								})}
							</div>
							{secondarySeeMore && (
								<Link className="link--small" to={secondarySeeMore.url}>
									{secondarySeeMore.text}
								</Link>
							)}
						</div>
					)}

					<div className="menu__section menu__section--gray">
						<div className="menu__section__title">{tertiaryTitle}</div>
						<div className="grid-header">
							{tertiaryItems.map((item, index) => {
								const cardTitle = item.text ?? item.title;
								return (
									<CardPost
										key={index}
										name={cardTitle}
										image={item.image}
										horizontal
										tag={capitalizeString(item.tag)}
										tagClass={getTagColorClass(item.tag)}
										customClass={cardPostClass}
										url={item.url}
									/>
								);
							})}
						</div>
						{tertiarySeeMore && (
							<Link className="link--small" to={tertiarySeeMore.url}>
								{tertiarySeeMore.text}
							</Link>
						)}
					</div>
				</div>
			)}
			{blog && kc && (
				<div className="item__body">
					<div className="menu__section">
						<div className="grid-header grid-header--resources">
							<CardPost image={blog.icon} name={blog.description} url={blog.url} />
							<CardPost image={kc.icon} name={kc.description} url={kc.url} />
						</div>
					</div>
					<div className="menu__section menu__section--gray">
						<Newsletter
							lang={lang}
							id="newsletter-menu-mobile"
							title={newsletter.resources_newsletter_title}
							description={newsletter.resources_newsletter_description}
							form={newsletter.resources_newsletter_form}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

const getTagColorClass = (tag) => {
	let colorClass = '';

	if (tag) {
		switch (tag.toLowerCase()) {
			case 'ebook':
				colorClass = 'tag--section--orange';
				break;

			case 'blog':
				colorClass = 'tag--section--purple';
				break;

			default:
				colorClass = 'tag--section--cherry-pie';
				break;
		}
	}

	return colorClass;
};
