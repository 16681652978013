import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';

export const LanguageDropdown = ({lang, translates, shortLabel = false}) => {
    const [openLanguage, setOpenLanguage] = useState(false)

    const onFocusOut = () => {
        setTimeout(() => setOpenLanguage(false), 500)
    }

    const changeLang = (value) => {
        if (translates && translates[value]) {
            return navigate(translates[value])
        }
    }

	// If language stored, use this language, else use the browsers language
	// if (isLanguageStored()) {
	// 	const storedLang = getStoredLanguage()
	// 	// Check if the used language and the used language is the same, if not, store the new language
	// 	if (storedLang !== lang) {
	// 		storeLanguage(lang)
	// 		changeLang(getStoredLanguage())
	// 	}
	// 	// Use the stored language
	// } else if(getBrowserLanguage()) {
	// 	// Use the browser language
	// 	const browserLang = getBrowserLanguage()
	// 	storeLanguage(browserLang)
	// 	changeLang(getStoredLanguage())
	// }

	return (
		<div className={`dropdown ${openLanguage ? 'dropdown--open' : ''}`} onBlur={onFocusOut}>
			<button className="dropdown__trigger input" onClick={() => setOpenLanguage(!openLanguage)}>
				<span>{lang === 'es' ? 
                    shortLabel ? 'ES' : 'Español' : 
                    shortLabel ? 'EN' : 'English'}</span>
				<i className="icon-chevron-down"></i>
			</button>
			<div className="dropdown__menu">
				<div
					className={`dropdown__menu__item ${lang === 'es' ? 'dropdown__menu__item--selected' : ''}`}
					onClick={() => changeLang('es')}
				>
					{shortLabel ? 'ES' : 'Español'}
				</div>
				<div
					className={`dropdown__menu__item ${lang === 'en' ? 'dropdown__menu__item--selected' : ''}`}
					onClick={() => changeLang('en')}
				>
					{shortLabel ? 'EN' : 'English'}
				</div>
			</div>
		</div>
	);
};

export const LanguagePills = ({ lang, translates }) => {
	return (
		<div className="tabs tabs--pills">
			{translates &&
				Object.entries(translates).map(([key, value]) => {
					return (
						<Link
							to={value}
							className={`tabs__tab ${key === lang ? 'tabs__tab--active' : ''}`}
							key={key}
						>
							{key}
						</Link>
					);
				})}
		</div>
	);
};

const store = (typeof localStorage !== "undefined" ? localStorage : null)
const nav = (typeof navigator !== "undefined" ? navigator : null)

// const isLanguageStored = () => {
// 	if (getStoredLanguage()) {
// 		return true
// 	}
// 	return false
// }

// const getStoredLanguage = () => {
// 	return store?.getItem('lang')
// }

// const storeLanguage = (lang) => {
	
// 	if (lang !== 'es') {
// 		lang = 'en'
// 	}
// 	store?.setItem('lang', lang)
// }

// const getBrowserLanguage = () => {
// 	if (nav?.language.indexOf('-') >= 0) {
// 		const langAr = nav?.language.split('-')
// 		return langAr[0]
// 	}
// 	return nav?.language
// }
